@import url("https://fonts.googleapis.com/css?family=Catamaran:400,700");

body {
  margin: 0 0;
  padding: 0;
  background: #fff;
  color: #333;
  font-weight: 400;
  font-size: 16px;
}

a {
  color: #007ace;
}

.span-link {
  color: #007ace;
  cursor: pointer;
  font-size: 13px;
  margin-left: 10px;
}

a:hover {
  text-decoration: none;
}

.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.container-box {
  background: #fff;
  border: #dfe3e8;
  border-radius: 0.25rem;
  padding: 20px;
  margin-bottom: 30px;
  border: 1px solid #dfe3e8;
  min-height: 100px;
}

.container-box h4 {
  color: #333;
}

.container-box h4 span {
  float: right;
  font-size: 0.8rem;
}

.container-box h4 span a {
  font-weight: normal;
}

.form-container {
  display: block;
  margin: 0;
}

.form-container h5 {
  font-size: 1rem;
  font-weight: 600;
  margin: 10px 0 5px;
}

.form-container .description {
  color: #919eab;
  font-size: 0.8rem;
}

.block {
  display: block;
  width: 100%;
  margin: 0 0 30px;
}

.divider {
  display: block;
  width: 100%;
  height: 1px;
  background: #dfe3e8;
  margin: 0 0 30px;
}

h1 {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 30px;
}

h1 span {
  float: right;
}

h1 span a:hover {
  color: #fff;
}

h1.has-subtitle {
  margin-bottom: 5px;
}

h4 {
  color: #666;
  font-size: 1.1rem;
  margin-bottom: 30px;
}

main {
  clear: both;
  float: left;
  position: relative;
  width: 100%;
}

.left-column {
  width: 20%;
  float: left;
}

.right-column {
  width: 75%;
  float: left;
}

.center-column {
  width: 60%;
  float: left;
  padding-top: 15px;
}

.end-column {
  width: 20%;
  float: left;
  text-align: right;
  padding-top: 20px;
}

.left-column-m {
  width: 225px;
  float: left;
}

.right-column-m {
  width: calc(100% - 225px);
  float: left;
}

.single-column {
  width: 100%;
}

.login-screen {
  width: 100%;
}

.cal-slot {
  padding: 30px 0;
  text-align: center;
  border: 1px solid #919eab;
  margin-bottom: 20px;
  border-radius: 5px;
}

.cal-slot-selected {
  background: rgba(21, 75, 111, 0.1);
}

.image-box {
  width: 100%;
}

.image-box img {
  width: 100%;
  height: auto;
}

.tree a {
  color: #007ace !important;
  cursor: pointer;
}

.formula-preview {
  white-space: pre-wrap;
  /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
}

.tree-frame .tree-group {
  background: #efefef;
  line-height: 40px;
  padding-left: 15px;
  font-size: 1rem;
  font-weight: 600;
  margin-top: 15px;
}

.tree-frame .tree-group:first-of-type {
  margin-top: 0;
}

.tree-frame .tree-block {
  border: 1px solid #efefef;
  padding: 10px;
}

.main-container {
  position: relative;
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
}

.luna-title {
  font-family: "Mary Ann", "Times New Roman", Times, serif;
  color: #e55000;
  font-weight: 800;
  text-align: center;
  margin: 20px 0;
  padding: 0;
}

.luna-h3 {
  font-family: "Mary Ann", "Times New Roman", Times, serif;
  color: #e55000;
  font-weight: 800;
  line-height: 110px;
  text-align: left;
  margin: 0 0 0 40px;
  padding: 0;
}

.luna-h1 {
  line-height: 48px;
}

.sidebar-label {
  font-size: 16px;
  line-height: 1.18182;
  font-weight: 700;
  font-family: MaryAnn, "Trebuchet MS", Arial, Helvetica, sans-serif;
  margin-bottom: 4px;
}

.sidebar-text {
  font-size: 16px;
}

.luna-h4 {
  font-size: 16px;
  line-height: 1.18182;
  font-weight: 700;
  font-family: MaryAnn, "Trebuchet MS", Arial, Helvetica, sans-serif;
  margin-bottom: 20px;
}

.dashboard-notification {
  background: #d50000;
  padding: 15px;
  color: #fff;
  margin-bottom: 30px;
}

.dashboard-notification div {
  display: inline;
}

.dashboard-notification div.ln-c-alert__icon-wrapper {
  margin-right: 5px;
}

.ok-notification {
  background: #2e7d32;
  padding: 15px;
  color: #fff;
  margin-bottom: 30px;
}

.amber-notification {
  background: #ffbf00;
  padding: 15px;
  color: #fff;
  margin-bottom: 30px;
}

.incident-modal {
  position: fixed;
  top: 30px;
  left: 50%;
  margin-left: -300px;
  z-index: 99;
  width: 600px;
  border: 3px solid #000;
  background: #fff;
}

.incident-modal .ms-close {
  text-align: right;
  height: 30px;
  padding: 5px;
  position: relative;
}

.incident-modal .ms-close svg {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.incident-modal .incident-form .ln-c-form-group {
  margin-bottom: 0 !important;
}

.incident-modal .incident-form .ln-c-label {
  margin-bottom: 0 !important;
}

.modal-shadow {
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

header {
  position: relative;
}

.chart {
  font-size: 0.6rem;
  display: block;
  width: 100%;
}

.content {
  padding-top: 30px;
}

.loader {
  text-align: center;
  padding: 40px 0;
}

.loader div {
  margin: 0 auto;
}

.block-link {
  display: block;
  position: relative;
  padding-left: 50px;
  margin: 0 0 30px;
}

.block-link .icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  background: #f4f6f8;
  text-align: center;
  line-height: 40px;
}

.block-link .icon i {
  color: #919eab;
}

.block-link p {
  font-size: 0.9rem;
  margin: 0;
  padding: 0;
}

.block-link p a {
  font-weight: bold;
}

.confirmed,
.partial,
.yellow-warning {
  border-radius: 10px;
  padding: 4px 10px;
  background: #ffea8a;
  font-size: 12px;
}

.cancelled,
.empty,
.red-warning {
  border-radius: 10px;
  padding: 4px 10px;
  background: #fead9a;
  font-size: 12px;
}

.soldout,
.grey-warning {
  border-radius: 10px;
  padding: 4px 10px;
  background: #dfe3e8;
  color: #576675;
  font-size: 12px;
}

.back {
  display: block;
  color: #919eab;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.18182;
  font-weight: 700;
  font-family: MaryAnn, "Trebuchet MS", Arial, Helvetica, sans-serif;
}

.back i {
  margin-right: 10px;
}

.export {
  font-size: 13px;
  margin-top: -20px;
  margin-bottom: 20px;
}

.export a {
  color: #637381;
}

.quote-price {
  background: #f4f6f8;
  border: 1px solid #154b6f;
  border-radius: 4px;
  font-size: 12px;
  padding: 4px 8px;
}

.force-wrap {
  word-break: break-word;
}

.small-link {
  color: #007ace !important;
  font-style: normal;
  font-size: 13px;
  cursor: pointer;
}

.btn {
  background: linear-gradient(to bottom, #f00, #e56f0e);
  color: #fff;
  font-size: 0.8rem;
  padding-left: 20px;
  padding-right: 20px;
  outline: 0;
  display: inline-block;
  margin: 0 0 0 5px;
}

.inline-btn {
  margin-left: 0;
}

.btn:hover {
  background: linear-gradient(to bottom, #d80000, #f00);
}

.btn-secondary {
  background: linear-gradient(to bottom, white, #f9fafb);
  border: 1px solid #dfe3e8;
  color: #919eab;
}

.btn-header {
  background: #2fb254;
  color: #fff;
  font-size: 11px;
}

.btn-header:hover {
  background: #2fb254;
}

.btn-secondary:hover {
  background: linear-gradient(to bottom, #efefef, #f9fafb);
  border: 1px solid #dfe3e8;
  color: #919eab;
}

.btn-alert {
  background: linear-gradient(to bottom, red, darkred);
  border: 1px solid darkred;
  color: #fff;
}

.btn-alert:hover {
  background: linear-gradient(to bottom, darkred, red);
}

.btn-full {
  width: 100%;
  height: 38px;
}

.invalid-error {
  color: #f00;
  font-size: 11px;
  font-weight: bold;
  padding-top: 3px;
}

.form-group label .asterix {
  color: #f00;
  margin-left: 3px;
}

.form-control {
  font-size: 0.9rem;
  outline: 0;
}

.error {
  font-weight: bold;
  font-size: 0.8rem;
  color: #f00;
}

.form-check {
  margin: 0 0 5px;
  font-size: 0.9rem;
}

.form-controls {
  margin: 0 0 30px;
}

.delete-item {
  cursor: pointer;
}

header {
  clear: both;
  float: left;
  width: 100%;
  background: #fff;
  color: #333;
  font-size: 0.8rem;
  line-height: 70px;
  border-bottom: 2px solid #d8d8d8;
}

header a {
  cursor: pointer;
  color: #154b6f;
}

header .logo {
  padding: 20px 0 20px 15px;
}

header .logo img {
  height: auto;
  width: 292px;
}

header .header-search {
  position: relative;
  padding-top: 15px;
}

header .header-search input {
  position: relative;
  background: #f4f6f8;
  margin: 0;
  height: 38px;
  padding: 0 5px;
  line-height: 38px;
  font-style: italic;
  color: #dfe3e8;
}

header .header-search input:before {
  position: absolute;
  display: block;
  z-index: 99;
  right: 10px;
  top: 5px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "aaa";
  color: #000;
  width: 20px;
  height: 20px;
  background: red;
}

header .header-search .results {
  position: absolute;
  top: 100%;
  left: 0;
  overflow: hidden;
  width: calc(100% - 30px);
  margin-top: -10px;
  border-radius: 3px;
  background-color: white;
  -webkit-box-shadow: 0 0 0 1px rgba(6, 44, 82, 0.1),
    0 2px 16px rgba(33, 43, 54, 0.08);
  box-shadow: 0 0 0 1px rgba(6, 44, 82, 0.1), 0 2px 16px rgba(33, 43, 54, 0.08);
  margin-left: 15px;
  margin-right: 15px;
  line-height: 1rem;
}

header .header-search .results .results-top {
  background: #dfe3e8;
  padding: 8px 15px;
  text-transform: uppercase;
  color: #888;
  font-size: 11px;
  font-weight: bold;
}

header .header-search .results .results-list {
  padding: 10px 15px;
  max-height: 400px;
  overflow-y: scroll;
}

header .header-search .results .results-list .list-item {
  padding: 5px 0;
  border-bottom: 1px dashed #dfe3e8;
}

header .header-search .results .results-list .list-item h4 {
  padding: 0;
  margin: 0;
  font-size: 13px;
  color: #007ace;
  cursor: pointer;
}

header .header-search .results .results-list .list-item p {
  padding: 0;
  margin: 0;
  font-size: 11px;
  color: #666;
}

header .header-search .results .results-list .list-item:last-of-type {
  border-bottom: 0;
}

.sub-nav {
  clear: both;
  width: 100%;
  position: relative;
  line-height: 70px;
}

.sub-nav nav {
  display: table;
  /* Allow the centering to work */
  margin: 0 auto;
  line-height: 70px;
}

.sub-nav nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sub-nav nav ul li {
  display: inline;
  margin: 0 15px;
  padding: 0;
  line-height: 70px;
}

.sub-nav nav ul li a {
  font-size: 1.325rem;
  line-height: 1.23077;
  font-weight: 700;
  font-family: MaryAnn, "Trebuchet MS", Arial, Helvetica, sans-serif;
  color: #333;
  transition: all 0.5s;
}

.sub-nav-version {
  position: absolute;
  top: 0;
  right: 0;
  color: #4c4c4c;
  text-align: right;
  font-style: italic;
  font-size: 11px;
  transition: color 0.4s ease 0s;
  padding-right: 30px;
  line-height: 1em;
  padding-top: 10px;
}

.sub-nav-text {
  position: absolute;
  bottom: 0;
  right: 0;
  font-family: "Mary Ann", "Times New Roman", Times, serif;
  color: #4c4c4c;
  font-weight: 600;
  text-align: right;
  transition: color 0.4s ease 0s;
  padding-right: 30px;
}

.login {
  padding-top: 100px;
}

.login p {
  text-align: center;
}

.login img {
  margin-bottom: 20px;
}

.menu {
  position: relative;
  border-right: 1px solid #ced4da;
  min-height: calc(100vh - 70px);
  padding: 20px 15px 10px;
}

.menu nav {
  width: 210px;
}

.menu nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.menu nav ul li {
  position: relative;
  margin: 0;
  padding: 0;
  line-height: 40px;
}

.menu nav ul li i {
  position: absolute;
  left: 0;
  top: 0;
  height: 40px;
  line-height: 40px;
  width: 30px;
  text-align: center;
  font-size: 1.2rem;
  color: #919eab;
  transition: all 0.5s;
}

.menu nav ul li a {
  display: block;
  font-size: 1.325rem;
  line-height: 1.23077;
  font-weight: 700;
  font-family: MaryAnn, "Trebuchet MS", Arial, Helvetica, sans-serif;
  color: #333;
  transition: all 0.5s;
  margin-bottom: 20px;
}

.menu nav ul li span {
  float: right;
  background: #dfe3e8;
  border: 1px solid #fff;
  text-align: center;
  border-radius: 2rem;
  padding: 0 8px;
  line-height: 20px;
  margin-top: 9px;
  font-size: 0.8rem;
}

.menu nav ul li a:hover {
  color: #154b6f;
  text-decoration: none;
}

.menu nav ul li a:hover i {
  color: #333;
}

.menu nav ul li.meta p {
  font-size: 13px;
  color: #666;
  text-align: center;
}

.menu nav ul li.active a {
  color: #154b6f;
}

.menu nav.base {
  position: absolute;
  bottom: 0;
}

.panel {
  background: #fff;
  border-left: 1px solid #dfe3e8;
  min-height: calc(100vh - 56px);
  padding-top: 30px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 0 0 1rem;
  font-size: 0.9rem;
}

table tr {
  border-bottom: 1px solid #dfe3e8;
  background: #fff;
  transition: background 0.3s;
}

table tr th:last-child,
table tr td:last-child {
  text-align: right;
}

table thead tr th {
  font-size: 16px;
  line-height: 1.18182;
  font-weight: 700;
  font-family: MaryAnn, "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-bottom: 1px solid #d8d8d8;
  padding-bottom: 15px;
}

table tbody tr:hover {
  background: #f4f6f8;
}

table td {
  line-height: 2.6rem;
  padding: 0 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
}

table td a {
  font-size: 13px;
}

table th.text-cell,
table td.text-cell {
  white-space: normal;
  overflow: visible;
  text-overflow: clip;
  text-align: left !important;
  line-height: 1.6rem;
}

table tr:nth-child(2n) {
  background: #f2f2f2;
}

.tabs {
  border-bottom: 1px solid #dfe3e8;
  margin: 0 0 10px;
}

.tabs .tab {
  display: inline-block;
  line-height: 40px;
  padding: 0 10px;
}

.tabs .tab a {
  color: #333;
  font-size: 0.9rem;
}

.tabs .tab.active {
  border-bottom: 3px solid #f00;
}

.paging {
  text-align: center;
}

.paging .prev,
.paging .next {
  color: #919eab;
  background: #f4f6f8;
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  outline: 0;
}

.paging .prev i,
.paging .next i {
  color: #919eab;
}

.paging p {
  font-size: 0.8rem;
}

.paging .disabled {
  opacity: 0.3;
}

.table-button {
  position: relative;
  text-align: right;
}

.incident-form {
  line-height: 50px;
}

.incident-form .invalid-error {
  line-height: 20px;
}

.incident-form .input-group {
  margin-bottom: 0 !important;
}

.incident-link {
  font-weight: bold;
  color: #0052cc;
  text-decoration: underline;
}

.text-center {
  text-align: center;
}
